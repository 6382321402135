import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
  type ForwardedRef,
  type KeyboardEvent,
  type ReactNode,
} from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useQueryClient } from "@tanstack/react-query";
import {
  AlertTriangle,
  ArrowRight,
  Clock,
  Loader2,
  MapPin,
  X,
} from "lucide-react";

import {
  applicationPageVar,
  expertPanelVar,
  Pages,
  panelFlowTypeVar,
} from "@/variables/globalVar";
import { type AddressSuggestion } from "shared/apiClient";
import { FlowType } from "shared/types";
import { titleCase } from "shared/lib";

import {
  getAccountQueryKey,
  useGetAddressSuggestions,
  useSelectAddress,
} from "shared/apiHooks";
import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

export interface AddressContainerAPI {
  submitSelectedAddress: () => void;
}

export interface AddressContainerProps {
  onSelectedAddress?: (addressId: string) => void;
  onSubmitAddressCompleted?: () => void;
  onSubmitAddressFailed?: () => void;
  placeholder?: string;
  prefixIcon?: ReactNode;
  showSelectButton?: boolean;
}

export const AddressContainer = forwardRef(function AddressContainer(
  {
    onSelectedAddress,
    onSubmitAddressCompleted,
    onSubmitAddressFailed,
    placeholder,
    prefixIcon,
    showSelectButton = true,
  }: AddressContainerProps,
  ref: ForwardedRef<AddressContainerAPI>,
) {
  const queryClient = useQueryClient();

  const setApplicationPageVar = useSetAtom(applicationPageVar);
  const setPanelFlowTypeVar = useSetAtom(panelFlowTypeVar);

  const expertPanel = useAtomValue(expertPanelVar);

  const { application, conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  const [searchInput, setSearchInput] = useState("");
  const [errMessage, setErrMessage] = useState<ReactNode>("");
  const [predictionResults, setPredictionResults] = useState<
    Array<AddressSuggestion>
  >([]);
  const [items, setItems] = useState<Array<AddressSuggestion>>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedAddressId, setSelectedAddressId] = useState<string>("");

  const texts = useMemo(
    () => [
      "👀 Looking up the zone...",
      "🌳 Checking for overlays...",
      "📐 Measuring the lot size...",
      "🛰️ Finding an aerial photo...",
    ],
    [],
  );

  const [currentText, setCurrentText] = useState<string>(texts[0]);

  const { isPending: selectAddressLoading, mutate: selectAddress } =
    useSelectAddress({
      onSuccess: (response) => {
        onSubmitAddressCompleted?.();
        queryClient.invalidateQueries({ queryKey: getAccountQueryKey() });
        setPanelFlowTypeVar(
          response.data?.panel_flow_type || FlowType.fast_enquiry,
        );
        setApplicationPageVar(Pages.App);
      },
      onError: (error) => {
        console.error("<AddressContainer> useSelectAddress() onError()", error);
        onSubmitAddressFailed?.();
        const response = error.response;
        if (
          response &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          setErrMessage(response.data[0]);
        } else {
          setErrMessage(
            <>
              <p>There was an error retrieving details about this address.</p>
              <p className="mt-1">
                Try refreshing your browser and entering the address again. If
                that doesn’t work,{" "}
                <span className="text-textLink hover:text-textLinkHover underline">
                  <a
                    href={getLocalisationEntry("councilContactLink")}
                    rel="noreferrer"
                    tabIndex={0}
                    target="_blank"
                  >
                    contact council
                  </a>
                </span>{" "}
                to discuss your proposal.
              </p>
            </>,
          );
        }
      },
    });

  const {
    isPending: addressSuggestionsLoading,
    isSuccess: addressSuggestionsSuccess,
    mutate: getAddressSuggestions,
  } = useGetAddressSuggestions({
    onSuccess: (response) => {
      setPredictionResults(response.data?.suggestions || []);
      console.log(
        "<AddressContainer> useGetAddressSuggestions() onSuccess()",
        response.data,
      );
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentIndex = texts.indexOf(currentText);
      const nextIndex = (currentIndex + 1) % texts.length;
      setCurrentText(texts[nextIndex]);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [currentText, texts]);

  useEffect(() => {
    const unparseditems = localStorage.getItem("searchSuggest");
    if (unparseditems) {
      const parseditems = JSON.parse(unparseditems);
      if (parseditems) {
        setItems(parseditems);
      }
    }
  }, []);

  useEffect(() => {
    if (items.length !== 0) {
      localStorage.setItem("searchSuggest", JSON.stringify(items));
    }
  }, [items]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchInput.length !== 0) {
        getAddressSuggestions({
          body: { address: searchInput },
          path: { application_id: application?.id || "" },
        });
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [application, getAddressSuggestions, searchInput]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    e.preventDefault();
    setSearchInput(value);
    if (value === "") {
      setErrMessage("");
      setPredictionResults([]);
    }
    setShowAutoComplete(value !== "");
    setShowHistory(value === "");
  };

  const handleSelectAddress = (addressId: string) => {
    setSelectedAddressId(addressId);
    onSelectedAddress?.(addressId);
  };

  const handleSubmitAddress = useCallback(() => {
    setErrMessage("");
    if (selectedAddressId) {
      selectAddress({
        body: {
          address_id: selectedAddressId,
          ...(expertPanel?.version !== undefined &&
          expertPanel?.version !== null
            ? { panel_version: expertPanel.version }
            : {}),
        },
        path: { application_id: application?.id || "" },
      });
      setShowAutoComplete(false);
      setShowHistory(false);
    }
  }, [
    application,
    expertPanel,
    selectAddress,
    selectedAddressId,
  ]);

  useImperativeHandle(ref, () => {
    return {
      submitSelectedAddress: () => {
        handleSubmitAddress();
      },
    };
  }, [handleSubmitAddress]);

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Handle keyboard events for the input field
    if (event.key === "Tab") {
      event.preventDefault();
      if (showAutoComplete) {
        setSelectedItemIndex((prevIndex) =>
          prevIndex < predictionResults?.length - 1 ? prevIndex + 1 : 0,
        );
      } else if (showHistory) {
        setSelectedItemIndex((prevIndex) =>
          prevIndex < items.length - 1 ? prevIndex + 1 : 0,
        );
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      // Handle Enter key press
      if (
        predictionResults?.findIndex(
          (element) =>
            element.address.toUpperCase() === searchInput.toUpperCase(),
        ) !== -1 &&
        searchInput.length !== 0
      ) {
        handleSubmitAddress();
      }
      const selectedList = showAutoComplete ? predictionResults : items;
      const selectedItem = selectedList[selectedItemIndex];
      if (selectedItemIndex >= 0 && selectedItemIndex < selectedList.length) {
        setShowAutoComplete(false);
        setShowHistory(false);
        handleSelectAddress(selectedItem.address_id);
        setSearchInput(titleCase(selectedItem.address));

        setItems((prevItems) => {
          let sendBool = true;
          prevItems.forEach((item, index) => {
            if (item.address_id === selectedItem.address_id) {
              prevItems.splice(index, 1);
              sendBool = false;
            }
          });
          if (sendBool) {
            if (prevItems.length === 5) {
              prevItems.pop();
            }
            return [selectedItem, ...prevItems];
          } else {
            return prevItems;
          }
        });

        setSelectedItemIndex(-1);
      }
    }
  };

  return (
    <div className="h-[60px] w-full overflow-visible">
      <div className="flex w-full flex-row">
        <div
          tabIndex={-1}
          className={`${errMessage !== "" ? "border-destructiveDefault" : "border-interactiveBorder focus-within:border-interactiveBorderSelect"} bg-interfaceWhite flex w-full cursor-text flex-col items-center justify-center overflow-hidden rounded-xl border shadow-sm selection:border-0`}
          onBlur={(_event) => {
            if (inputRef.current) {
              inputRef.current.blur();
            }
            setSelectedItemIndex(-1);
            setShowAutoComplete(false);
            setShowHistory(false);
          }}
          onFocus={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
            setShowAutoComplete(searchInput !== "");
            setShowHistory(searchInput === "");
          }}
        >
          <div className="flex w-full flex-row px-6 py-4">
            {prefixIcon ? <div className="mr-2">{prefixIcon}</div> : null}
            <input
              className="bg-interfaceWhite text-textPrimary placeholder:text-textSubdued placeholder:focus:text-textPrimary grow appearance-none items-center p-0 text-lg leading-[22px] outline-0"
              id="address-search"
              name="address-search"
              autoComplete="off"
              ref={inputRef}
              placeholder={placeholder || "Search an address"}
              onChange={handleChange}
              onKeyDown={handleInputKeyDown}
              value={searchInput}
              type="search"
            />
            {searchInput.length > 0 && !selectAddressLoading && (
              <X
                aria-label="clear"
                className="text-textPrimary ml-3 cursor-pointer"
                onClick={() => {
                  setSearchInput("");
                  setPredictionResults([]);
                  setErrMessage("");
                }}
                role="button"
              />
            )}
          </div>
          <div
            hidden={!(showAutoComplete || showHistory)}
            onKeyDown={handleInputKeyDown}
            className="z-10 max-h-[180px] w-full overflow-y-auto md:max-h-full"
          >
            <div>
              {searchInput?.length !== 0 && predictionResults?.length === 0 && (
                <div>
                  <div className="border-interfaceBorder text-textPrimary flex h-[60px] flex-row items-center border-t bg-[#f5f5f5] px-5 py-3 text-lg leading-[22px]">
                    <div className="text-textSubdued overflow-hidden text-ellipsis whitespace-nowrap">
                      No address found
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              {searchInput !== "" &&
                predictionResults?.map((result, index) => {
                  return (
                    <div
                      key={result.address_id}
                      onClick={() => {
                        console.log("<AddressContainer> onClick()", result);
                        setSearchInput(titleCase(result.address));
                        handleSelectAddress(result.address_id);
                        setShowAutoComplete(false);
                        setShowHistory(false);

                        setItems((prevItems) => {
                          let sendBool = true;
                          prevItems.forEach((item, index) => {
                            if (item.address_id === result.address_id) {
                              prevItems.splice(index, 1);
                              sendBool = false;
                            }
                          });
                          if (sendBool) {
                            if (prevItems.length === 5) {
                              prevItems.pop();
                            }
                            return [result, ...prevItems];
                          } else {
                            return prevItems;
                          }
                        });
                      }}
                      className={`text-textPrimary hover:bg-interactiveBgHover flex h-[60px] cursor-pointer flex-row items-center px-5 py-3 text-lg 
                          ${selectedItemIndex === index ? "bg-interactiveBgHover" : ""} 
                          ${index === 0 ? "border-interfaceBorder border-t" : ""}`}
                    >
                      <MapPin className="h=[24px] text-textPrimary mr-3 min-h-[24px] w-[24px] min-w-[24px]" />
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {titleCase(result.address)}
                      </div>
                    </div>
                  );
                })}
              {searchInput === "" &&
                items.map((result, index) => {
                  return (
                    <div
                      key={result.address_id}
                      onClick={() => {
                        handleSelectAddress(result.address_id);
                        setSearchInput(titleCase(result.address));
                        setShowAutoComplete(false);
                        setShowHistory(false);
                      }}
                      className={`text-textPrimary hover:bg-interactiveBgHover focus:bg-interactiveBgHover flex h-[60px] cursor-pointer flex-row items-center px-5 py-3 text-lg 
                          ${selectedItemIndex === index ? "bg-interactiveBgHover" : ""} 
                          ${index === 0 ? "border-interfaceBorder border-t" : ""}`}
                    >
                      <Clock className="h=[24px] text-textPrimary mr-3 w-[24px]" />
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {titleCase(result.address)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {showSelectButton ? (
          <button
            aria-label="Submit address"
            className="self-start p-5"
            disabled={
              !(
                predictionResults?.findIndex(
                  (element) =>
                    element.address.toUpperCase() === searchInput.toUpperCase(),
                ) !== -1 && searchInput.length !== 0
              )
            }
            onClick={handleSubmitAddress}
          >
            {selectAddressLoading ? (
              <Loader2 className="text-textPrimary h-[20px] w-[20px] animate-spin" />
            ) : (
              <ArrowRight
                className={`${
                  predictionResults?.findIndex(
                    (element) =>
                      element.address.toUpperCase() ===
                      searchInput.toUpperCase(),
                  ) !== -1 && searchInput.length !== 0
                    ? "text-textPrimary"
                    : "text-textDisabled"
                } h-[20px] w-[20px]`}
              />
            )}
          </button>
        ) : null}
      </div>

      {selectAddressLoading && (
        <div className="text-textSubdued mt-3 text-left text-base leading-5">
          {currentText}
        </div>
      )}

      {errMessage && typeof errMessage === "string" ? (
        <label
          className="text-textPrimary mt-2 flex flex-row items-start gap-2 text-left text-base leading-5"
          htmlFor="address-search"
        >
          <div className="text-destructiveText mt-1">
            <AlertTriangle className="max-h-4 max-w-4" />️
          </div>
          <div
            className="text-destructiveText [&>a]:text-textLink [&>a]:hover:text-textLinkHover [&>a]:underline"
            dangerouslySetInnerHTML={{ __html: errMessage }}
          />
        </label>
      ) : null}

      {errMessage && typeof errMessage !== "string" ? (
        <label
          className="text-textPrimary mt-2 flex flex-row items-start gap-2 text-left text-base leading-5"
          htmlFor="address-search"
        >
          <div className="text-destructiveText mt-1">
            <AlertTriangle className="max-h-4 max-w-4" />️
          </div>
          <div className="text-destructiveText">{errMessage}</div>
        </label>
      ) : null}
    </div>
  );
});
