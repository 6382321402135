import { useEffect } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { parseExpertResult } from "shared/lib";
import { type Conversation, type Message } from "shared/apiClient";
import { AccountType, FlowType } from "shared/types";
import { sortByDate } from "@/lib/utils";
import {
  accountVar,
  chatLockVar,
  isLoadingVar,
  messagesVar,
  noticeboardDataVar,
  panelFlowTypeVar,
  tabAnimationVar,
  tabNotificationsVar,
  Tabs,
  tabSelectedVar,
} from "@/variables/globalVar";

import { useGetConversationMessages } from "shared/apiHooks";
import { useMediaQuery } from "shared/hooks";
import { useAccount } from "@/hooks/useAccount";
import { useChannel, type SubscriptionDescriptors } from "shared/channel";

import { Chat } from "@/components/chat/Chat";
import { LotAndProposalTab, Wizard, WizardTabs } from "@/components/wizard";
import { ContentContainer } from "@/components/containers/ContentContainer";
import { MobileTabs } from "@/components/MobileTabs";
import { LotContainer } from "@/components/lot";
import { PermissibilityContainer } from "@/components/containers/PermissibilityContainer";
import { ProposalContainer } from "@/components/proposal";

export function MyLotPage() {
  const { channel } = useChannel();

  const { application, conversation } = useAccount();
  const [, setAccountVar] = useAtom(accountVar);
  const [messages, setMessagesVar] = useAtom(messagesVar);

  const [tabSelected, setTabSelectedVar] = useAtom(tabSelectedVar);
  const setTabAnimationVar = useSetAtom(tabAnimationVar);
  const [tabNotifications, setTabNotifications] = useAtom(tabNotificationsVar);

  const setChatLockVar = useSetAtom(chatLockVar);
  const setIsLoadingVar = useSetAtom(isLoadingVar);

  const [noticeboardData, setNoticeboardDataVar] = useAtom(noticeboardDataVar);

  const panelFlowType = useAtomValue(panelFlowTypeVar);

  const md = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const shouldShowSideBySide = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  useEffect(() => {
    if (panelFlowType !== FlowType.fast_enquiry) {
      setTabSelectedVar(md ? Tabs.Lot : Tabs.Chat);
    }
  }, [md]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    data: conversationMessagesResponse,
    error: conversationMessagesError,
    isLoading: isLoadingConversationMessages,
  } = useGetConversationMessages(
    {
      path: {
        application_id: application?.id || "",
        conversation_id: conversation?.id || "",
      },
      query: {
        page: 1,
        page_size: 9999,
      },
    },
    {
      enabled: !!conversation,
    },
  );

  useEffect(() => {
    console.log(
      "<MyLotPage> conversationMessagesResponse.data",
      conversationMessagesResponse?.data,
    );
    const results = conversationMessagesResponse?.data.results || [];

    const mergedMessages = results.reduce((merged, newMessage) => {
      const alreadyExists = messages.some(
        (existingMessage) => existingMessage.id === newMessage.id,
      );
      if (!alreadyExists) {
        merged.push(newMessage);
      }
      return merged;
    }, [] as Array<Message>);

    const sortedMessages = mergedMessages.sort(sortByDate);

    const latestMessage = sortedMessages[sortedMessages.length - 1];
    if (latestMessage?.sender_account_type === AccountType.ASSISTANT) {
      setIsLoadingVar(false);
    }

    setMessagesVar(() => {
      if (sortedMessages.length > 0) {
        return sortedMessages;
      }
      return [];
    });
  }, [conversationMessagesResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const conversationSubscriptionDescriptor: SubscriptionDescriptors = {
      event_type: "CONVERSATION",
      callback: (event) => {
        const innerEvent = event.data;
        switch (innerEvent.event_type) {
          case "MESSAGE_CREATED":
            console.log(
              "<MyLotPage> message created",
              innerEvent,
              conversation?.id,
            );
            if (conversation?.id === innerEvent.data.conversation_id) {
              const alreadyExists = messages.some(
                (message) => message.id === innerEvent.data.id,
              );
              if (!alreadyExists) {
                setMessagesVar((prev) => {
                  return [...prev, innerEvent.data];
                });
                if (
                  innerEvent.data.sender_account_type === AccountType.ASSISTANT
                ) {
                  setIsLoadingVar(false);
                }
              }
            }
            break;
          case "PHASE_UPDATED":
            if (conversation?.id === innerEvent.data.conversation_id) {
              setAccountVar((draft) => {
                const currentApplication = draft?.current_application;
                if (currentApplication?.current_conversation) {
                  currentApplication.current_conversation.phase = innerEvent
                    .data.phase as Conversation["phase"];
                }
              });
            }
            break;
        }
      },
    };
    channel?.subscribe(conversationSubscriptionDescriptor);

    const noticeboardSubscriptionDescriptor: SubscriptionDescriptors = {
      event_type: "NOTICEBOARD",
      callback: (event) => {
        const innerEvent = event.data;
        switch (innerEvent.event_type) {
          case "EXPERT_STARTED":
            // if (conversation?.id === innerEvent.data.conversation_id) {
            //   setNoticeboardDataVar((draft) => {
            //     draft[innerEvent.data.expert_name] = parseExpertResult(
            //       innerEvent.data,
            //     );
            //   });
            // }
            break;
          case "NOTICEBOARD_UPDATED":
            if (conversation?.id === innerEvent.data.conversation_id) {
              setNoticeboardDataVar((draft) => {
                draft[innerEvent.data.expert_name] = parseExpertResult(
                  innerEvent.data,
                );
              });
            }
            break;
        }
      },
    };
    channel?.subscribe(noticeboardSubscriptionDescriptor);

    return () => {
      channel?.unsubscribe(conversationSubscriptionDescriptor);
      channel?.unsubscribe(noticeboardSubscriptionDescriptor);
    };
  }, [channel, conversation, messages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (md && messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      const assistantUtterances = messages.filter(
        (message) => message.sender_account_type === AccountType.ASSISTANT,
      );
      if (
        assistantUtterances.length === 4 &&
        latestMessage.sender_account_type === AccountType.ASSISTANT
      ) {
        setTabAnimationVar(true);
        setTabSelectedVar(Tabs.Proposal);
        setTimeout(() => {
          setTabAnimationVar(false);
        }, 1000);
      }
    }
  }, [messages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("<MyLotPage> noticeboardData updated:", noticeboardData);
  }, [noticeboardData]);

  useEffect(() => {
    if (conversation?.address_data) {
      if (tabSelected !== Tabs.Lot) {
        setTabNotifications({
          ...tabNotifications,
          lot: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conversation?.address_data?.lot_size_sqm,
    conversation?.address_data?.unstructured_address,
    conversation?.address_data?.overlays,
    conversation?.address_data?.zones,
  ]);

  useEffect(() => {
    if (noticeboardData.land_use_terms?.parsed_output?.existing_land_use_term) {
      if (tabSelected !== Tabs.Lot) {
        setTabNotifications({
          ...tabNotifications,
          proposal: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticeboardData.land_use_terms?.parsed_output?.existing_land_use_term]);

  useEffect(() => {
    if (
      noticeboardData.land_use_terms?.parsed_output?.proposed_land_use_term &&
      tabSelected !== Tabs.Proposal &&
      messages.filter(
        (message) => message.sender_account_type === AccountType.ASSISTANT,
      ).length >= 4
    ) {
      setTabNotifications({
        ...tabNotifications,
        proposal: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticeboardData.land_use_terms?.parsed_output?.proposed_land_use_term]);

  useEffect(() => {
    if (noticeboardData.project_details) {
      if (tabSelected !== Tabs.Proposal) {
        if (
          messages.filter(
            (message) => message?.sender_account_type === AccountType.ASSISTANT,
          ).length >= 4
        ) {
          setTabNotifications({
            ...tabNotifications,
            proposal: true,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticeboardData.project_details]);

  useEffect(() => {
    console.log("<MyLotPage> convPhase:", conversation?.phase);
    const shouldLockChat =
      panelFlowType !== FlowType.fast_enquiry &&
      [
        "",
        "ready_for_assessment",
        "report_ready",
        "amendment",
        "saved_report",
        "sent_to_council",
        "off_topic",
      ].includes(conversation?.phase || "");
    setChatLockVar(shouldLockChat);

    if (
      [
        "ready_for_assessment",
        "report_ready",
        "amendment",
        "saved_report",
        "sent_to_council",
      ].includes(conversation?.phase || "") &&
      tabSelected !== Tabs.Permissibility
    ) {
      setTabSelectedVar(Tabs.Permissibility);
    }
  }, [conversation?.phase]); // eslint-disable-line react-hooks/exhaustive-deps

  if (panelFlowType !== FlowType.fast_enquiry) {
    return (
      <div className="flex h-full min-h-0 grow flex-col md:flex-row">
        <section className="min-h-0 grow overflow-hidden bg-white md:w-1/2">
          {(tabSelected === Tabs.Chat && !md) || md ? <Chat /> : null}
          {!md && tabSelected === Tabs.Lot && <LotContainer />}
          {!md && tabSelected === Tabs.Proposal && <ProposalContainer />}

          {!md && tabSelected === Tabs.Permissibility && (
            <PermissibilityContainer />
          )}
        </section>

        <div className="bg-interfaceBgDefault hidden h-full grow md:block md:w-1/2">
          <ContentContainer />
        </div>

        <div className="md:hidden">
          <MobileTabs />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex h-full min-h-0 grow flex-col">
        <section className="min-h-0 grow overflow-hidden bg-white">
          {tabSelected !== Tabs.LotAndProposal && <Wizard />}
          {tabSelected === Tabs.LotAndProposal && <LotAndProposalTab />}
        </section>

        {["ready_for_assessment", "report_ready"].includes(
          conversation?.phase || "",
        ) && !shouldShowSideBySide ? (
          <WizardTabs />
        ) : null}
      </div>
    );
  }
}
