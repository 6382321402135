import { ProgressIndicatorListStatus } from "shared/components";
import { ProgressIndicatorItemMap } from "@/components/assessmentReport/lib";

export interface Question {
  allow_multiple: boolean;
  choices: Array<{
    label: string;
    value: string;
    icon?: string;
    color?: string;
  }>;
  dependency?: {
    depends_on_question: number;
    depends_on_choice: number;
  };
  question_text?: string;
  question_subtitle?: string;
  text_field_lines?: number;
  text_field_placeholder?: string;
}

export interface QuestionData {
  image_url?: string;
  image_url_title?: string;
  questions?: Array<Question>;
  question_subtitle?: string;
  question_title: string;
  next_button_label?: string;
  required?: boolean;
}

export const reportLoadingProgressIndicatorItemsMap: ProgressIndicatorItemMap =
  {
    land_use_terms: {
      status: ProgressIndicatorListStatus.none,
      text: "Assessing the land use against your zone requirements",
      icon: "🔍",
    },
    land_use_assessor: {
      status: ProgressIndicatorListStatus.none,
      text: "Reviewing buildings and works conditions against overlay legislation",
      icon: "🚧",
    },
    zone_assessor_checker: {
      status: ProgressIndicatorListStatus.none,
      text: "Evaluating potential impacts to vegetation",
      icon: "🌳",
    },
    State_Demo_Report: {
      status: ProgressIndicatorListStatus.none,
      text: "Summarising findings",
      icon: "📝",
    },
  };
