import { type ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import cookie from "cookie";

import { client } from "shared/apiClient";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

client.setConfig({
  baseURL: import.meta.env.VITE_REACT_APP_BACKEND_ENDPOINT,
  throwOnError: true,
  withCredentials: true,
});

client.instance.interceptors.request.use((config) => {
  const cookies = cookie.parse(document.cookie);
  config.headers.set('X-CSRFToken', cookies["csrftoken"] );

  return config;
});

export interface ApiProviderProps {
  children: ReactNode;
}

export function ApiProvider({ children }: ApiProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
