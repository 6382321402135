import { useAtomValue, useSetAtom } from "jotai";
import { useQueryClient } from "@tanstack/react-query";
import { Loader2, X } from "lucide-react";

import { ConversationPhase, FlowType } from "shared/types";
import { ShowReportState } from "@/types/reports";
import { EventTypesEnum } from "@/types/events";
import {
  applicationPageVar,
  messagesVar,
  Pages,
  panelFlowTypeVar,
  Sections,
  wizardNavigatorVar,
} from "@/variables/globalVar";
import { getAccountQueryKey, useCreateApplication } from "shared/apiHooks";

import { EditSquareIcon, OutgoingEmailIcon } from "@/components/icons";
import {
  Button,
  DialogContent,
  DialogClose,
  DialogTitle,
} from "shared/components";

export interface RestartDialogProps {
  conversationId: string;
  conversationPhase: ConversationPhase;
  onOpenChange: (open: boolean) => void;
  showReport: ShowReportState;
}

export function RestartDialog({
  conversationId,
  conversationPhase,
  onOpenChange,
  showReport,
}: RestartDialogProps) {
  const queryClient = useQueryClient();

  const panelFlowType = useAtomValue(panelFlowTypeVar);
  const setWizardNavigatorVar = useSetAtom(wizardNavigatorVar);
  const setApplicationPageVar = useSetAtom(applicationPageVar);
  const setMessagesVar = useSetAtom(messagesVar);

  const { isPending: createApplicationLoading, mutate: createApplication } =
    useCreateApplication({
      onSuccess: (response) => {
        console.log(
          "<AddressPage> createApplication() onSuccess",
          response.data,
        );
        onOpenChange(false);
        setMessagesVar([]);
        setApplicationPageVar(Pages.Address);
        setWizardNavigatorVar(Sections.Address);
        queryClient.invalidateQueries({ queryKey: getAccountQueryKey() });
      },
    });

  const handleClickRestart = () => {
    console.log("<RestartDialog> handleClickRestart()");
    const abortCreateApplication = new AbortController();
    createApplication({
      body: { panel_flow_type: panelFlowType },
      signal: abortCreateApplication.signal,
    });
  };

  const handleClickDiscard = () => {
    console.log("<RestartDialog> handleClickDiscard()");
    const abortCreateApplication = new AbortController();
    createApplication({
      body: { panel_flow_type: panelFlowType },
      signal: abortCreateApplication.signal,
    });
  };

  const handleClickAdjustPlans = () => {
    onOpenChange(false);
    const adjustPlansEvent = new CustomEvent(EventTypesEnum.AdjustPlans);
    document.dispatchEvent(adjustPlansEvent);
  };

  const handleClickSaveReport = () => {
    onOpenChange(false);
    const savePlansEvent = new CustomEvent(EventTypesEnum.SaveReport);
    document.dispatchEvent(savePlansEvent);
  };

  const titleText =
    conversationPhase === "saved_report" ||
    panelFlowType === FlowType.fast_enquiry
      ? "Start again?"
      : "Discard and restart?";

  return (
    <DialogContent
      aria-describedby={undefined}
      className="max-w-[600px] gap-0 rounded-none border-0 p-0 px-5"
      hideCloseButton
    >
      <DialogTitle className="sr-only">{titleText}</DialogTitle>
      <div className="bg-interfaceWhite flex flex-col rounded-3xl pt-5 md:pt-6">
        <DialogClose
          aria-label="Close"
          className="mr-5 self-end md:mr-6"
          role="button"
        >
          <X className="text-textPrimary h-[24px] w-[24px]" />
        </DialogClose>

        <h2 className="text-textPrimary md:px-15 px-8 text-[28px] font-semibold leading-8">
          {titleText}
        </h2>

        <div className="md:px-15 mt-3 flex flex-col gap-[16px] px-8">
          {panelFlowType === FlowType.fast_enquiry ? (
            <p className="text-textPrimary text-lg leading-[22px]">
              Restarting will start a new conversation, beginning at entering an
              address.
            </p>
          ) : null}

          {conversationPhase === "saved_report" &&
          panelFlowType !== FlowType.fast_enquiry ? (
            <>
              <p className="text-textPrimary text-lg leading-[22px]">
                Restarting will start a new conversation, beginning at entering
                an address.
              </p>
              <p className="text-textPrimary text-lg leading-[22px]">
                You’ll still be able to access the report and other details from
                this conversation via an email that will be sent to you after
                the report has been reviewed.
              </p>
            </>
          ) : null}

          {conversationPhase !== "saved_report" &&
          panelFlowType !== FlowType.fast_enquiry ? (
            <>
              <p className="text-textPrimary text-lg leading-[22px]">
                If you discard and restart, you will lose all the responses
                you’ve entered. Your lot details, proposal, and other
                information will also be lost.
              </p>

              {
                // User has not accepted terms and clicked generate report.
              }
              {!showReport.visible || showReport.conversationId !== conversationId ? (
                <div className="bg-interfaceBgDefault flex flex-col gap-[4px] rounded-[2px] border-l-4 px-[16px] py-[12px]">
                  <h3 className="text-textPrimary text-lg font-bold leading-[22px]">
                    ☝ Want to adjust your proposal or fix a response?
                  </h3>
                  <p className="text-textPrimary">
                    ️Instead of restarting, answer a few more questions. You’ll
                    have an opportunity to adjust your proposal once all
                    questions have been answered and the draft report is
                    generated.
                  </p>
                </div>
              ) : null}

              {
                // User has accepted terms and clicked generate report.
                // Also, the report has already finished generating for the first time.
              }
              {showReport.visible &&
              showReport.conversationId === conversationId &&
              conversationPhase !== "ready_for_assessment" ? (
                <div className="bg-interfaceBgDefault flex flex-col gap-[16px] rounded-[2px] border-l-4 px-[16px] py-[12px]">
                  <h3 className="text-textPrimary text-lg font-bold leading-[22px]">
                    ☝ Before you discard and restart...
                  </h3>
                  <div>
                    <h4 className="text-textPrimary text-sm font-bold">
                      Try adjusting your proposal
                    </h4>
                    <p className="text-textPrimary text-sm">
                      Your adjustments will be taken into account and a new
                      report will be generated, potentially with a different
                      result.
                    </p>
                    <Button
                      className="mt-[8px] bg-white"
                      onClick={handleClickAdjustPlans}
                      variant="outline"
                    >
                      <EditSquareIcon className="mr-[6px]" /> Adjust proposal
                    </Button>
                  </div>
                  <div>
                    <p className="text-textPrimary text-sm font-bold">
                      Submit your report for review
                    </p>
                    <p className="text-textPrimary text-sm">
                      After the report has been reviewed, you’ll receive a link
                      to your report by email. This is a free service.
                    </p>
                    <Button
                      className="mt-[8px] bg-white"
                      onClick={handleClickSaveReport}
                      variant="outline"
                    >
                      <OutgoingEmailIcon className="mr-[6px]" /> Submit for
                      review
                    </Button>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        <div className="md:px-15 mt-[24px] flex flex-row-reverse gap-x-3 px-8 pb-8 md:pb-12">
          <Button
            className="border-actionDefault bg-actionDefault text-textWhite rounded border px-4 py-2 font-semibold"
            disabled={createApplicationLoading}
            onClick={
              conversationPhase === "saved_report"
                ? handleClickRestart
                : handleClickDiscard
            }
          >
            {createApplicationLoading && (
              <>
                <Loader2 className="animate-spin" />{" "}
              </>
            )}
            {conversationPhase === "saved_report" ||
            panelFlowType === FlowType.fast_enquiry
              ? "Restart"
              : "Discard and restart"}
          </Button>

          <DialogClose
            className="border-actionDefault bg-interfaceWhite text-actionDefault rounded border px-4 py-2 font-semibold"
            disabled={createApplicationLoading}
          >
            Cancel
          </DialogClose>
        </div>
      </div>
    </DialogContent>
  );
}
