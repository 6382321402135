import { useMemo } from "react";
import { useAtomValue } from "jotai";

import { AccountType } from "shared/types";
import { sortByDate } from "@/lib/utils";
import { messagesVar } from "@/variables/globalVar";

export function useLatestUserMessage() {
  const messages = useAtomValue(messagesVar);

  return useMemo(() => {
    return messages
      .filter((message) => message.sender_account_type === AccountType.USER)
      .sort(sortByDate)
      .pop();
  }, [messages]);
}
