import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Loader2 } from "lucide-react";

import { FlowType } from "shared/types";
import { EventTypesEnum } from "@/types/events";

import {
  chatLockVar,
  noticeboardDataVar,
  showReportVar,
  tabAnimationVar,
} from "@/variables/globalVar";

import { useSaveReport } from "shared/apiHooks";
import { useAssessmentLights } from "shared/hooks";
import {
  useAccount,
  useAmendedUserMessages,
  useLatestUserMessage,
  useTracking,
} from "@/hooks";

import { createMessageEvent, useChannel } from "shared/channel";

import { EditSquareIcon, OutgoingEmailIcon } from "@/components/icons";

import { AssessmentReport, Button, NextSteps } from "shared/components";
import { CallOut, CallOutTypes } from "@/components/CallOut";
import { ConfirmReport } from "@/components/ConfirmReport";
import { ReportWarningAccordion } from "@/components/ReportWarningAccordion";
import { AdjustPlansDialog } from "@/components/AdjustPlansDialog";
// import { SaveReportDialog } from "@/components/SaveReportDialog";
// import { ReportSavedDialog } from "@/components/ReportSavedDialog";
// import { ReportSavedPanel } from "@/components/ReportSavedPanel";
import { ReportAdjustedPanel } from "@/components/ReportAdjustedPanel";
import { SubmitReportForReviewDialog } from "@/components/SubmitReportForReviewDialog";
import { ReportGeneratedPanel } from "@/components/ReportGeneratedPanel";
import { ReportSubmittedForReviewDialog } from "@/components/ReportSubmittedForReviewDialog";
import { ReportSubmittedForReviewPanel } from "@/components/ReportSubmittedForReviewPanel";
import { ReportReviewedPanel } from "@/components/ReportReviewedPanel";
import { AmendingReportLoaderContainer } from "@/components/assessmentReport/AmendingReportLoaderContainer";
import { ReportLoaderContainer } from "@/components/assessmentReport/ReportLoaderContainer";

export function PermissibilityContainer() {
  const { channel } = useChannel();

  const setChatLockVar = useSetAtom(chatLockVar);
  const tabAnimBool = useAtomValue(tabAnimationVar);

  const { stateChange } = useTracking("<PermissibilityContainer>");

  const { isPending: isReportSaving, mutate: saveReport } = useSaveReport({
    onSuccess() {
      setShowReportVar({
        visible: true,
        conversationId: conversation?.id || "",
      });
      setSaveReportDialogOpen(false);
      setReportSavedDialogOpen(true);
    },
    onError(error) {
      console.error(
        "<PermissibilityContainer> useSaveReport() onError()",
        error,
      );
    },
  });

  const { account, conversation } = useAccount();

  const latestUserMessage = useLatestUserMessage();
  const amendedUserMessages = useAmendedUserMessages();

  const noticeboardData = useAtomValue(noticeboardDataVar);
  const assessmentLights = useAssessmentLights(conversation, noticeboardData);

  const [showReport, setShowReportVar] = useAtom(showReportVar);

  const [adjustPlansDialogOpen, setAdjustPlansDialogOpen] = useState(false);
  const [saveReportDialogOpen, setSaveReportDialogOpen] = useState(false);
  const [reportSavedDialogOpen, setReportSavedDialogOpen] = useState(false);
  const [waitingForAmendingPhase, setWaitingForAmendingPhase] = useState(false);
  const [reportLoading, setReportLoading] = useState<boolean>(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const setShowReportState = (visible: boolean) => {
    if (visible) {
      console.log("<PermissibilityContainer> Lock input");
      setChatLockVar(true);
    }
    setShowReportVar({ visible, conversationId: conversation?.id || "" });
    localStorage.setItem(
      "showReport",
      JSON.stringify({ visible, conversationId: conversation?.id || "" }),
    );
  };

  useEffect(() => {
    setShowReportVar(JSON.parse(localStorage.getItem("showReport") || "{}"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleAdjustPlansEvent: EventListener = (_event) => {
      setAdjustPlansDialogOpen(true);
    };
    document.addEventListener(
      EventTypesEnum.AdjustPlans,
      handleAdjustPlansEvent,
    );

    const handleSaveReportEvent: EventListener = (_event) => {
      setSaveReportDialogOpen(true);
    };
    document.addEventListener(EventTypesEnum.SaveReport, handleSaveReportEvent);

    return () => {
      document.removeEventListener(
        EventTypesEnum.AdjustPlans,
        handleAdjustPlansEvent,
      );
      document.removeEventListener(
        EventTypesEnum.SaveReport,
        handleSaveReportEvent,
      );
    };
  }, []);

  useEffect(() => {
    if (conversation?.phase === "report_ready" && showReport.visible) {
      stateChange("/report");
    } else {
      stateChange("/reportLoading");
    }
  }, [conversation?.phase, stateChange, showReport.visible]);

  useEffect(() => {
    if (conversation?.phase === "saved_report") {
      setShowReportVar({
        visible: true,
        conversationId: conversation?.id || "",
      });
    }
  }, [conversation?.phase]); // eslint-disable-line

  useEffect(() => {
    if (conversation?.phase === "amendment" && waitingForAmendingPhase) {
      setWaitingForAmendingPhase(false);
    }
  }, [conversation?.phase]); // eslint-disable-line

  useEffect(() => {
    if (
      !waitingForAmendingPhase &&
      ["report_ready", "saved_report", "sent_to_council"].includes(
        conversation?.phase || "",
      )
    ) {
      setReportLoading(false);
    } else {
      setReportLoading(true);
    }
  }, [conversation?.phase, waitingForAmendingPhase]);

  const handleClickAdjustPlans = (text: string) => {
    setAdjustPlansDialogOpen(false);
    setWaitingForAmendingPhase(true);

    if (channel && conversation?.id) {
      const messageDescriptor = createMessageEvent({
        conversation_id: conversation.id,
        message_type: "AMENDMENT",
        text,
      });
      console.log(
        "<PermissibilityContainer> handleClickAdjustPlans()",
        messageDescriptor,
      );
      channel.sendMessage(messageDescriptor);
    }

    if (containerRef.current) {
      containerRef.current.scroll({ top: 0, behavior: "smooth" });
    }
  };

  const handleClickSaveReport = () => {
    setSaveReportDialogOpen(true);
  };

  const handleSaveReport = () => {
    if (conversation?.id) {
      saveReport({
        path: { conversation_id: conversation.id },
      });
    }
  };

  const renderReportNotReadyView = () => {
    return (
      <div className="mt-[12px]">
        <CallOut
          type={CallOutTypes.WHITE}
          header="🤔 I can’t determine if your plans will require a planning permit or not yet"
          text="Respond to a few more questions in the chat to describe your plans in more detail. 6 to 8 detailed responses are usually enough. Once you have given enough information, the permit check will appear here."
        />
      </div>
    );
  };

  return (
    <>
      <div className="h-full min-h-0 grow items-start overflow-y-auto">
        <div
          className={`${tabAnimBool ? "animate-slide-in" : ""} bg-interfaceBgDefault flex h-full w-full flex-col justify-start overflow-y-auto p-6 pb-[84px] align-top md:px-10 md:py-10`}
          ref={containerRef}
        >
          <div>
            <h1 className="text-textPrimary text-[28px] font-semibold leading-8">
              Planning enquiry report
            </h1>
          </div>

          {![
            "ready_for_assessment",
            "report_ready",
            "amendment",
            "saved_report",
            "sent_to_council",
          ].includes(conversation?.phase || "") ? (
            renderReportNotReadyView()
          ) : !showReport.visible ||
            showReport.conversationId !== conversation?.id ? (
            <ConfirmReport setShowReport={setShowReportState} />
          ) : (
            <>
              {/* Report has been generated for the first time */}
              {!waitingForAmendingPhase &&
              ["report_ready"].includes(conversation?.phase || "") &&
              amendedUserMessages.length === 0 ? (
                <div className="mb-6 mt-[12px] md:mb-10">
                  <ReportGeneratedPanel
                    onClickAdjustProposals={() =>
                      setAdjustPlansDialogOpen(true)
                    }
                    onClickSubmit={handleClickSaveReport}
                  />
                </div>
              ) : null}

              {/* Report has been adjusted */}
              {!waitingForAmendingPhase &&
              ["report_ready"].includes(conversation?.phase || "") &&
              amendedUserMessages.length > 0 ? (
                <div className="mb-6 mt-[12px] md:mb-10">
                  <ReportAdjustedPanel
                    amendedUserMessages={amendedUserMessages}
                    onClickSubmit={handleClickSaveReport}
                  />
                </div>
              ) : null}

              {waitingForAmendingPhase ? <div className="mb-6" /> : null}

              {/* Report has been saved/submitted for review */}
              {conversation?.phase === "saved_report" ? (
                <div className="mb-6 mt-[20px] md:mb-10">
                  <ReportSubmittedForReviewPanel email={account?.email || ""} />
                  {/*<ReportSavedPanel*/}
                  {/*  conversationId={conversation?.id || ""}*/}
                  {/*  email={account?.email || ""}*/}
                  {/*/>*/}
                </div>
              ) : null}

              {/* Report has been reviewed */}
              {conversation?.phase === "sent_to_council" ? (
                <div className="mb-6 mt-[20px] md:mb-10">
                  <ReportReviewedPanel
                    conversationId={
                      conversation?.identifier?.toUpperCase() || ""
                    }
                    email={account?.email || ""}
                  />
                </div>
              ) : null}

              {/* Report loading indicators */}
              {reportLoading ? (
                <div className="mb-6 mt-[20px] md:mb-10">
                  {waitingForAmendingPhase ||
                  conversation?.phase === "amendment" ? (
                    <AmendingReportLoaderContainer
                      amendedUserMessages={amendedUserMessages}
                      latestUserMessage={latestUserMessage}
                      noticeboardData={noticeboardData}
                    />
                  ) : (
                    <ReportLoaderContainer
                      latestUserMessage={latestUserMessage}
                      noticeboardData={noticeboardData}
                    />
                  )}
                </div>
              ) : null}

              {/* ASSESSMENT REPORT */}
              {!reportLoading ? (
                <div className="mb-6 md:mb-10">
                  <AssessmentReport
                    conversation={conversation}
                    expertMap={
                      conversation?.frontend_expert_output_config?.["enquiry"]
                    }
                    flowType={FlowType.full_assessment}
                    noticeboardData={noticeboardData}
                    onClickAdjustReport={() => setAdjustPlansDialogOpen(true)}
                  />

                  <div className="pt-4 md:pt-8">
                    <NextSteps
                      assessmentLights={assessmentLights}
                      conversation={conversation}
                    />
                  </div>
                </div>
              ) : null}

              <ReportWarningAccordion />
            </>
          )}
        </div>
      </div>

      {showReport.visible &&
      showReport.conversationId === conversation?.id &&
      ["report_ready"].includes(conversation?.phase || "") ? (
        <div
          id="permissibility-footer"
          className="border-interactiveBorder bg-interfaceBgDefault absolute inset-x-0 bottom-[66px] mx-2 flex justify-between gap-[12px] rounded border p-2 md:static md:mx-0 md:w-full md:rounded-none md:border-0 md:border-t md:bg-white md:p-3"
        >
          <Button
            className="border-textLink text-textLink w-full"
            disabled={waitingForAmendingPhase || isReportSaving}
            onClick={() => setAdjustPlansDialogOpen(true)}
            variant="outline"
          >
            {waitingForAmendingPhase && (
              <>
                <Loader2 className="animate-spin" />{" "}
              </>
            )}
            <EditSquareIcon className="mr-[6px]" /> Adjust your plans
          </Button>
          <Button
            className="bg-textLink w-full text-white "
            disabled={waitingForAmendingPhase || isReportSaving}
            onClick={handleClickSaveReport}
            variant="outline"
          >
            {isReportSaving && (
              <>
                <Loader2 className="animate-spin" />{" "}
              </>
            )}
            <OutgoingEmailIcon className="mr-[6px]" /> Submit for review
          </Button>
        </div>
      ) : null}

      <AdjustPlansDialog
        onClickAdjustPlan={handleClickAdjustPlans}
        onOpenChange={setAdjustPlansDialogOpen}
        open={adjustPlansDialogOpen}
      />
      {
        // TODO: Don't remove the commented dialogs below
        // We're running an experiment where the user submits reports for review
      }
      {/*<SaveReportDialog*/}
      {/*  email={account?.email || ""}*/}
      {/*  isSaving={isReportSaving}*/}
      {/*  onClickSaveReport={handleSaveReport}*/}
      {/*  onOpenChange={setSaveReportDialogOpen}*/}
      {/*  open={saveReportDialogOpen}*/}
      {/*/>*/}
      {/*<ReportSavedDialog*/}
      {/*  conversationId={conversation?.id || ""}*/}
      {/*  email={account?.email || ""}*/}
      {/*  onOpenChange={setReportSavedDialogOpen}*/}
      {/*  open={reportSavedDialogOpen}*/}
      {/*/>*/}
      <SubmitReportForReviewDialog
        email={account?.email || ""}
        isSubmitting={isReportSaving}
        onClickSubmit={handleSaveReport}
        onOpenChange={setSaveReportDialogOpen}
        open={saveReportDialogOpen}
      />
      <ReportSubmittedForReviewDialog
        email={account?.email || ""}
        onOpenChange={setReportSavedDialogOpen}
        open={reportSavedDialogOpen}
      />
    </>
  );
}
