import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

export function HelpMessage() {
  const { conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  return (
    <div className="flex flex-col gap-[16px]">
      <p className="text-textPrimary text-lg leading-[1.2222]">
        If the tool isn’t working like you expect, try refreshing your browser.
      </p>
      <p className="text-textPrimary text-lg leading-[1.2222]">
        If you’re still experiencing issues or have a question, call{" "}
        {getLocalisationEntry("councilName")} on{" "}
        <span className="font-semibold">
          {getLocalisationEntry("councilPhoneNumber")}
        </span>
        .
      </p>
      <p className="text-textPrimary text-lg leading-[1.2222]">
        Alternatively, you can{" "}
        <a
          href={`mailto:${getLocalisationEntry("councilContactLink")}`}
          className="text-textLink hover:text-textLinkHover underline"
        >
          contact us
        </a>{" "}
        to report an issue or request a call back. You may also find an answer
        to your query in the{" "}
        <a
          href={getLocalisationEntry("councilPlanningWebsite")}
          className="text-textLink hover:text-textLinkHover underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          planning section
        </a>{" "}
        of our website.
      </p>
    </div>
  );
}
