import { useMemo } from "react";

import { type Message } from "shared/apiClient";

import {
  MultiSelectStep,
  TextMessageStep,
  TreeDistanceStep,
  TreeSizeStep,
} from "./widgets";

export interface WizardStepProps {
  messages: Array<Message>;
  message?: Message;
}

export function RenderWizardStep({ messages, message }: WizardStepProps) {
  const isLatestMessage = useMemo(() => {
    return messages[messages.length - 1]?.id === message?.id;
  }, [messages, message]);

  switch (message?.message_type) {
    case "STANDARD":
    case "AMENDMENT":
    case "OVERWRITE":
      return (
        <TextMessageStep
          key={message.id}
          message={message}
          showLoading={!isLatestMessage}
        />
      );
    case "CHOICES_QUESTION":
      return (
        <MultiSelectStep
          key={message.id}
          message={message}
          showLoading={!isLatestMessage}
        />
      );
    case "TREE_DIAMETER_QUESTION":
      return (
        <TreeSizeStep message={message} showLoading={!isLatestMessage} />
      );
    case "TREE_DISTANCE_QUESTION":
      return (
        <TreeDistanceStep message={message} showLoading={!isLatestMessage} />
      );
    default:
      return null;
  }
}
