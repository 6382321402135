import { useMemo } from "react";
import { useAtomValue } from "jotai/index";

import { sortByDate } from "@/lib/utils";
import { messagesVar } from "@/variables/globalVar";

export function useAmendedUserMessages() {
  const messages = useAtomValue(messagesVar);

  return useMemo(() => {
    return messages
      .filter((message) => message.message_type === "AMENDMENT")
      .sort(sortByDate);
  }, [messages]);
}
