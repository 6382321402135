import { type Message } from "shared/apiClient";
import { type Noticeboard } from "shared/lib";
import { getReportLoadingProgressIndicatorItems } from "./lib";

import { AmendingReportLoader } from "./AmendingReportLoader";

export interface ReportLoaderContainerProps {
  amendedUserMessages: Array<Message>;
  noticeboardData: Noticeboard;
  latestUserMessage?: Message;
}

export function AmendingReportLoaderContainer({
  amendedUserMessages,
  noticeboardData,
  latestUserMessage,
}: ReportLoaderContainerProps) {
  return (
    <AmendingReportLoader
      amendedUserMessages={amendedUserMessages}
      items={getReportLoadingProgressIndicatorItems(
        noticeboardData,
        parseInt(latestUserMessage?.message_number || "-999"),
      )}
    />
  );
}
