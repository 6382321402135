import { type Message } from "shared/apiClient";

import { CogIcon } from "@/components/icons";
import {
  ProgressIndicatorList,
  type ProgressIndicatorListProps,
} from "shared/components";

export interface AmendingReportLoaderProps {
  amendedUserMessages: Array<Message>;
  items: ProgressIndicatorListProps["items"];
}

export function AmendingReportLoader({
  amendedUserMessages,
  items,
}: AmendingReportLoaderProps) {
  return (
    <div>
      <div className="border-interfaceBorder bg-interfaceBgLight text-textSubdued content-start rounded-t-lg border px-10 py-8">
        <h2 className="text-textPrimary mb-1 flex flex-row text-[28px] font-semibold leading-8">
          <CogIcon className="mr-2 h-7 w-7 animate-spin [animation-duration:2s]" />
          Updating report
        </h2>
        <p className="text-textPrimary text-lg leading-[22px]">
          This typically takes about 90 seconds.
        </p>
        <p className="text-textSubdued mb-1 mt-2 text-base leading-5">
          Revising your proposal based on the following adjustment:
        </p>
        <p className="text-textPrimary mb-1 mt-2 border-l-2 pl-[10px] text-base leading-5">
          {amendedUserMessages.at(-1)?.text}
        </p>
      </div>
      <div className="border-interfaceBorder bg-interfaceWhite rounded-b-lg border-x border-b px-10 py-8">
        <p className="text-textSubdued text-lg leading-[22px]">
          The revised report will appear here when it has finished updating.
        </p>

        <div className="mt-[24px]">
          <ProgressIndicatorList items={items} />
        </div>
      </div>
    </div>
  );
}
