import { useEffect } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import "shared/style.css";
import "./App.css";

import {
  parseExpertResult,
  type Noticeboard,
  type NoticeboardResult,
} from "shared/lib";
import {
  accountVar,
  applicationPageVar,
  isAuthenticatedVar,
  noticeboardDataVar,
  Pages,
  panelFlowTypeVar,
} from "@/variables/globalVar";

import { useGetAccount, useGetAuthTest, useLogout } from "shared/apiHooks";
import { useChannel } from "shared/channel";
import { useCouncilLocalisation } from "shared/hooks";
import { useAccount, useTracking } from "@/hooks";

import { Toaster, useToast } from "shared/components";
import { CustomTitle } from "@/components/CustomTitle";

import { AuthPage } from "./pages/AuthPage";
import { MyLotPage } from "./pages/MyLotPage";
import { AddressPage } from "./pages/AddressPage";

const isDemo = import.meta.env.VITE_REACT_ENV === "demo";
const googleApiKey = import.meta.env.VITE_REACT_APP_GOOGLE_API_KEY;

export function App() {
  const { channel } = useChannel();

  const [applicationPage, setApplicationPageVar] = useAtom(applicationPageVar);
  const [isAuthenticated, setIsAuthenticatedVar] = useAtom(isAuthenticatedVar);
  const setAccountVar = useSetAtom(accountVar);
  const [, setNoticeboardDataVar] = useAtom(noticeboardDataVar);

  const { conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);
  const [panelFlowType, setPanelFlowTypeVar] = useAtom(panelFlowTypeVar);

  const { toast } = useToast();

  const { initTracking } = useTracking("<App>");

  useEffect(() => {
    if (!document.getElementById("google-maps-api")) {
      const loadGoogleMapsScript = () => {
        const script = document.createElement("script");
        script.id = "google-maps-api";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&loading=async`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      };
      if (!(window as any).google) {
        loadGoogleMapsScript();
      }
    }
  }, []);

  useEffect(() => {
    initTracking();
  }, [initTracking]);

  const { isError, isSuccess } = useGetAuthTest();

  const { mutate: logout } = useLogout({
    onSuccess: () => {
      console.log("<Header> authLogoutList() onSuccess()");
      setIsAuthenticatedVar(false);
      toast({
        title: "Logged out",
        description:
          "You have been logged out as the user you were logged in as cannot access this application",
      });
    },
    onError: (error) => {
      console.error("<Header> authLogoutList() onError()", error);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setIsAuthenticatedVar(true);
    } else if (isError) {
      setIsAuthenticatedVar(false);
    }
  }, [isError, isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: accountResponse } = useGetAccount({
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (isAuthenticated && channel && channel.isClosed) {
      channel.initSocket();
    }
  }, [channel, isAuthenticated]);

  useEffect(() => {
    console.log("<App> panelFlowType", panelFlowType);
    console.log("<App> accountResponse.data", accountResponse?.data);

    if (accountResponse?.data.id) {
      const account = accountResponse.data;
      setAccountVar(accountResponse?.data);
      setPanelFlowTypeVar(account.current_application?.panel_flow_type);

      const currentApplication = account.current_application;
      const noticeboard = currentApplication?.current_conversation?.noticeboard;
      if (noticeboard) {
        const noticeboardArray =
          noticeboard as unknown as Array<NoticeboardResult>;

        setNoticeboardDataVar({});
        setNoticeboardDataVar((draft) => {
          noticeboardArray.forEach((noticeboardResult) => {
            draft[noticeboardResult.expert_name] =
              parseExpertResult(noticeboardResult);
          }, {} as Noticeboard);
        });
      }
    }
  }, [accountResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // There is no current conversation or associated address, render the address selection wizard
    if (!conversation?.address_data) {
      setApplicationPageVar(Pages.Address);
    }
  }, [conversation]); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: In future, put all council names in different folder, json etc. and read it from there
  const councilName = isDemo ? "myLot" : getLocalisationEntry("councilName");

  return (
    <>
      <CustomTitle councilName={councilName} />

      {isAuthenticated && applicationPage === Pages.Address && <AddressPage />}
      {isAuthenticated && applicationPage === Pages.App && <MyLotPage />}
      {!isAuthenticated && <AuthPage />}

      <Toaster />
    </>
  );
}