import { useNavigate } from "react-router-dom";
import { HandIcon, X } from "lucide-react";

import { YarraRangesIcon } from "@/components/icons";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from "shared/components";
import { HelpMessage } from "@/components/HelpMessage";

export function LandingPage() {
  const navigate = useNavigate();

  function handleStart() {
    navigate("/");
  }

  return (
    <div className="flex h-screen w-screen flex-col">
      <div className="border-b-interactiveBorder flex items-center justify-between border-b bg-[#F3F2F0] py-3 pl-4 pr-3 md:h-[60px] md:py-0 md:pl-8 md:pr-9">
        <div className="flex h-[32.51px] w-[73.19px] items-center justify-center md:h-[36.57px] md:w-[82.34px]">
          <YarraRangesIcon />
        </div>

        <div className="flex flex-row">
          <Dialog>
            <DialogTrigger>
              <button className="border-interactiveBorder bg-interfaceWhite text-textSubdued md:rounded-5xl flex flex-row items-center justify-between rounded border px-3 py-1.5 text-xs leading-[14px]">
                <HandIcon className="text-textSubdued mr-0.5 h-[16px] w-[16px]" />
                Help
              </button>
            </DialogTrigger>
            <DialogContent className=" gap-0 rounded-none border-0 p-0 px-5" hideCloseButton>
              <div className="bg-interfaceWhite flex flex-col rounded-3xl pt-5 md:pt-6">
                <DialogClose className="mr-5 self-end md:mr-6">
                  <X className="text-textPrimary h-[24px] w-[24px]" />
                </DialogClose>
                <div className="text-textPrimary md:px-15 flex items-center gap-2 px-8 text-[28px] font-semibold leading-8">
                  <HandIcon className="text-textPrimary h-[24px] w-[24px]" />
                  Help
                </div>
                <div className="text-textPrimary md:px-15 mb-8 mt-6 px-8 text-lg leading-[22px] md:mb-12">
                  <HelpMessage />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="bg-interfaceBgDefault flex h-full w-full flex-col items-center overflow-y-scroll">
        <div className="bg-interfaceWhite p-6 md:m-12 md:max-w-[700px] md:rounded-lg md:px-12 md:py-8 md:shadow-md">
          <div className="text-textPrimary mb-5 text-[32px] font-semibold leading-10">
            Find out if you need a planning permit
          </div>
          <div className="text-textPrimary mb-4 text-lg leading-[22px]">
            Thank you for participating in the trial of Yarra Ranges’ new
            planning enquiry tool.
          </div>
          <div className="text-textPrimary mb-6 text-lg leading-[22px]">
            This tool is designed to help you find out whether your development
            proposal requires a planning permit, and if it does, what
            information you’ll need to provide when you apply for one.
          </div>
          <div className="text-textPrimary mb-3 text-[28px] font-semibold leading-8">
            How it works
          </div>
          <ul className="text-textPrimary mb-4 list-inside list-decimal text-lg leading-[22px]">
            <li className="mb-1">
              After pressing “start now”, you’ll be asked to sign up
            </li>
            <li className="mb-1">
              You’ll then be asked to enter the address of your development
              proposal
            </li>
            <li className="mb-1">
              After that, you’ll be asked a series of questions about your plans
            </li>
            <li className="mb-1">
              Once enough information has been gathered about your proposal, the
              tool will generate a report stating whether it’s likely a planning
              permit is required, and if it is, personalised guidance on how to
              apply.
            </li>
          </ul>
          <button
            onClick={handleStart}
            className="bg-actionDefault text-textWhite active:bg-actionPress md:hover:bg-actionHover mb-6 rounded px-4 py-2 text-lg font-semibold leading-[22px]"
          >
            Start now
          </button>
          <div className="border-actionBorder bg-actionBgDefault mb-6 border-l-4 p-5">
            <div className="text-textPrimary mb-1 text-lg font-semibold leading-[22px]">
              ☎️ We’ll contact you after you’re done
            </div>
            <div className="text-textPrimary text-base leading-5">
              A Yarra Ranges team member will contact you after you’re done to
              ask how you went and answer any questions you may have.
              <br />
              Because we’re currently testing this tool, the result provided by
              the tool will be reviewed by a council planner. If there are any
              corrections that need to be made, they’ll be communicated when we
              contact you.
            </div>
          </div>
          <div className="border-interfaceBorder bg-interfaceBgDefault border-l-4 p-5">
            <div className="text-textPrimary mb-1 text-lg font-semibold leading-[22px]">
              ⚠️ Information given by this service is not an official planning
              decision
            </div>
            <div className="text-textPrimary text-base leading-5">
              Always wait for verification from a planner before proceeding. If
              you commence development work without necessary permits, it may
              lead to enforcement action.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
